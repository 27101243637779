import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';

import { Box, Button, Accordion, AccordionSummary, Typography, AccordionDetails, Container } from '@mui/material';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { LoginLayout, PageTitle } from '@components/layout';

import {
  TermosDeUsoPrivacidade,
  TermosDeUso,
  TermosDefinicoes,
  PoliticaDePrivacidade as PV,
  Encerramento,
} from '@components/utils/politicasEtermos';

function PoliticaDePrivacidade() {
  const [expanded, setExpanded] = useState('');

  const container = useRef(null);

  const handleChange = (panel) => () => {
    container.current?.scrollTo({ top: 0, behavior: 'smooth' });
    setExpanded((old) => (old === panel ? '' : panel));
  };
  return (
    <LoginLayout
      contentProps={{
        FullContent: () => (
          <>
            <Box sx={{ width: 1, height: 1, overflowY: 'auto' }}>
              <Container maxWidth="md" sx={{ height: 1, py: '1.5rem' }}>
                <PageTitle title="Políticas e Termos" sx={{ mb: '2rem' }} />

                {[
                  { title: 'Termos de Uso e Privacidade', Component: TermosDeUsoPrivacidade },
                  { title: 'Termos e Definições', Component: TermosDefinicoes },
                  { title: 'Termos de Uso', Component: TermosDeUso },
                  { title: 'Política de Privacidade', Component: PV },
                  { title: 'Encerramento', Component: Encerramento },
                ].map((el, index) => (
                  <Accordion
                    key={`${el.title}-${index}`}
                    expanded={expanded === `panel-${index}`}
                    onChange={handleChange(`panel-${index}`)}
                  >
                    <AccordionSummary>
                      <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                        {el.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <el.Component />
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Box sx={{ height: '1.5rem' }} />
              </Container>
            </Box>
            <Container maxWidth="md">
              <Box sx={{ display: 'flex', width: '100%', borderTop: '1px solid var(--gray-2)', py: '0.5rem' }}>
                <Button onClick={() => navigate('/')}>
                  <ArrowRightAltIcon sx={{ transform: 'scale(-1)' }} />
                  início
                </Button>
              </Box>
            </Container>
          </>
        ),
        sx: {
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    ></LoginLayout>
  );
}

export default PoliticaDePrivacidade;
